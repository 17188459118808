import * as React from 'react';
import styled from 'styled-components';

import { JobDetails } from '../../components/JobDetails';
import { Body as BaseBody } from '../../components/Typography';
import projectManagerImage from '../../content/env-work-project-manager.jpg';

const Body = styled(BaseBody)({ maxWidth: 1000 });

const JobDetail = () => (
	<JobDetails
		name="Projektmanager (m/w/d) in der Software Entwicklung"
		image={projectManagerImage}
		color="#22b1b9"
		area="📈 Project Management"
		type="⏱ Full Time"
		location="📍 Stuttgart (Arena2036)"
		model="On-Site & Remote"
		about={
			<Body>
				Wir suchen eine(n) Projektmanager(in) (m/w/d). Du übernimmst dabei die Verantwortung und
				Sicherstellung für mehrere Projekte in der Softwareentwicklung für Smart Home Produkte.
				Werde ein Teil unseres Teams und trage maßgeblich zur Umsetzung neuer innovativer Produkte
				bei. Flexibles Arbeiten ist für uns selbstverständlich. Deshalb freuen wir uns auf Deine
				Bewerbung für unseren Standort in Stuttgart (Vaihingen & Bad Cannstatt).
			</Body>
		}
		responsibilities={[
			'Verantwortlicher Ansprechpartner für ein oder mehrere Projekte',
			'Erstellung, Abstimmung und Pflege einer Projektplanung mit Projektzielen',
			'Projekt-Konzeption mit direktem Kontakt zum Kunden und dem Entwicklungsteam',
			'Projekt-Koordination zur Umsetzung von Projekten mit dem Kunden und dem Entwicklungsteam',
			'Einbringung in Kunden-Terminen zur aktuellen und zukünftigen Projekten',
			'Führungsaufgaben innerhalb mehrerer Projekten',
			'Durchführung der projektspezifischen Kapazitätsplanungen und Aufwandsabschätzungen',
			'Erstellung von Angeboten mit der zugehörigen Kunden-Abstimmung',
			'Unterstützung und Förderung von Projektmitgliedern',
			'Kontinuierliche Verbesserung des Projektmanagement Prozesses',
		]}
		requirements={[
			'Erfahrung im Betreuen und Managen von Projekten',
			'Erfolgreich abgeschlossenes Studium im Bereich Wirtschaftsinformatik, Wirtschaftswissenschaften oder eine vergleichbare Ausbildung',
			'Erfahrung/Verständnis für Komponenten im Kontext der Softwareentwicklung im App-, Web-, Cloud oder Embedded-Bereich',
			'Erfahrung/Verständnis für den Entwicklungsprozess von Software (Bugs, Features,Releases)',
		]}
		qualification={[
			'Motiviert operative und strategische Entscheidungen mit Auswirkungen auf Projekte zu treffen',
			'Freundlich und Empathisch bei der Zusammenarbeit im Team',
			'Analytisches Vorgehen zur Begründung von Entscheidungen',
			'Flexibilität, um auf sich ändernde Projekt-Anforderungen einzugehen',
			'Interesse an Technologie - insbesondere Smart Home',
			'Effizientes und zielgerichtetes Arbeiten - u.A. durch das Anleiten anderer Team- oder Projektmitglieder',
			'Zuverlässigkeit und Verantwortungsbewusstsein',
			'Verhandlungssichere Kenntnisse in Deutsch und Englisch',
		]}
		offer={[
			'Starkes Team aus SW-Experten aus dem Bereichen Embedded, App, Web/Cloud und UI/UX',
			'Start-Up Mentalität, flache Hierarchien, wenig Bürokratie',
			'Familienfreundliche Arbeitsbedingungen durch flexible Arbeitszeiten',
			'Größtmögliche Flexibilität bei der Wahl des Arbeitsortes - ob in der hellen, modernen ARENA2036, in mobilen Arbeitsplätzen (Stuttgart, Römerkastell) oder Home Offices',
			'Kontinuierliche Weiterbildungs- und Entwicklungsmöglichkeiten durch jährliche Mitarbeitergespräche',
			'Hochwertige individuelle Arbeitsmittel',
			'Zuschuss zum öffentlichen Personennahverkehr',
			'Umfangreiche Maßnahmen zur betrieblichen Gesundheitsförderung, z. B. JobRad, Umkleiden & Duschen, Obstkorb, Getränke, Kaffee usw.',
			'Schließlich ein dynamisches Arbeitsumfeld mit flachen Hierarchien und viel Teamgeist',
		]}
	/>
);

export default JobDetail;
