import { HStack, VStack } from '@domir/react-stacks';
import * as React from 'react';
import styled from 'styled-components';

import { Tag } from '../../../shared/src/Tag';
import { thingosColors } from '../../../shared/src/Theme/theme';
import { IndexLayout } from '../layouts';
import { Contact } from './Contact';
import Container, { ColorContainer } from './Container';
import { HeaderImage } from './HeaderImage';
import { Page } from './Page';
import { Body as BaseBody, HeaderH1, HeaderH2, HeaderH3 } from './Typography';
import { Wave } from './Wave';

import marker from '../svgs/marker.svg';
import plusMarker from '../svgs/plusMarker.svg';

const Body = styled(BaseBody)({ maxWidth: 1000 });

const Li = styled.li({
	listStyle: 'none',
	position: 'relative',
	paddingLeft: '40px', // space between icon and text

	'&:before': {
		position: 'absolute',
		left: 0,
		top: 2, // we center the marker vertically by hand
		content: `url(${marker})`,
		display: 'inline-block',
	},
});

const PlusLi = styled.li({
	listStyle: 'none',
	position: 'relative',
	paddingLeft: '40px', // space between icon and text

	'&:before': {
		position: 'absolute',
		left: 0,
		top: 2, // we center the marker vertically by hand
		content: `url(${plusMarker})`,
		display: 'inline-block',
	},
});

type Props = {
	name: string;
	image: string;
	color: string;
	area: string;
	type: string;
	location: string;
	model: string;
	about: React.ReactElement;
	responsibilities: string[];
	requirements: string[];
	qualification: string[];
	offer: string[];
};
export const JobDetails: React.FC<Props> = props => {
	const {
		name,
		image,
		color,
		about,
		area,
		type,
		location,
		model,
		responsibilities,
		requirements,
		qualification,
		offer,
	} = props;

	return (
		<IndexLayout>
			<HeaderImage image={image} waveColor={thingosColors.background.dark} />
			<Page>
				<ColorContainer backgroundColor={thingosColors.background.dark}>
					<Container>
						<HStack spacing={16}>
							<VStack>
								<HeaderH3 style={{ marginBottom: '8px' }}>Area</HeaderH3>
								<Tag color={color}>{area}</Tag>
							</VStack>
							<VStack>
								<HeaderH3 style={{ marginBottom: '8px' }}>Type</HeaderH3>
								<Tag color={thingosColors.highlight.main}>{type}</Tag>
							</VStack>
							<VStack>
								<HeaderH3 style={{ marginBottom: '8px' }}>Location</HeaderH3>
								<Tag color={thingosColors.highlight.main}>{location}</Tag>
							</VStack>
							<VStack>
								<HeaderH3 style={{ marginBottom: '8px' }}>Working Model</HeaderH3>
								<Tag color={thingosColors.highlight.main}>{model}</Tag>
							</VStack>
						</HStack>
					</Container>
				</ColorContainer>
				<Wave color={thingosColors.background.dark} flipped="vertical" />
				<ColorContainer>
					<Container>
						<HeaderH1>{name}</HeaderH1>
						<HeaderH2>About ThingOS</HeaderH2>
						<Body>
							At ThingOS, we work on connectivity in the areas of smart home and smart industry. The
							focus of ThingOS is particularly on software development in the IoT area. ThingOS has
							been a subsidiary of Häfele SE & Co KG since 2022. Among other things, we develop
							firmware for smart lighting, iOS and Android apps for control and cloud services. The
							team consists of 15 highly motivated employees (development, UI/UX design, business
							development) who enjoy working together as a team very much and very efficiently.
						</Body>
					</Container>
				</ColorContainer>
				<Wave color={thingosColors.background.dark} />
				<ColorContainer backgroundColor={thingosColors.background.dark}>
					<Container>
						<HeaderH2>About the {name} Position</HeaderH2>
						{about}
					</Container>
				</ColorContainer>
				<Wave color={thingosColors.background.dark} flipped="vertical" />
				<ColorContainer>
					<Container>
						<HeaderH2>{name} Responsibilities</HeaderH2>
						<Body>
							<ul>
								{responsibilities.map((r, index) => (
									<Li key={index}>{r}</Li>
								))}
							</ul>
						</Body>
					</Container>
				</ColorContainer>
				<Wave color={thingosColors.background.dark} />
				<ColorContainer backgroundColor={thingosColors.background.dark}>
					<Container>
						<HeaderH2>{name} Requirements</HeaderH2>
						<Body>
							<ul>
								{requirements.map((r, index) => (
									<Li key={index}>{r}</Li>
								))}
							</ul>
						</Body>
					</Container>
				</ColorContainer>
				<Wave color={thingosColors.background.dark} flipped="vertical" />
				<ColorContainer>
					<Container>
						<HeaderH2>Personal Qualification Aspects</HeaderH2>
						<Body>
							<ul>
								{qualification.map((r, index) => (
									<Li key={index}>{r}</Li>
								))}
							</ul>
						</Body>
					</Container>
				</ColorContainer>
				<Wave color={thingosColors.background.dark} />
				<ColorContainer backgroundColor={thingosColors.background.dark}>
					<Container>
						<HeaderH2>What We Offer</HeaderH2>
						<Body>
							<ul>
								{offer.map((r, index) => (
									<PlusLi key={index}>{r}</PlusLi>
								))}
							</ul>
						</Body>
					</Container>
				</ColorContainer>
				<Wave color={thingosColors.background.dark} flipped="vertical" />
				<ColorContainer>
					<Container>
						<Contact
							title={'Wanna be a Part of Our Team?'}
							text={
								'Please send your application, Curriculum Vitae and a brief description of projects you have already done in this field. We are happy receiving your application and will respond within two working days.'
							}
							buttonText={'Apply Now'}
							url={'mailto:jobs@thingos.io'}
							backgroundColor={thingosColors.background.dark}
						/>
					</Container>
				</ColorContainer>
			</Page>
		</IndexLayout>
	);
};
