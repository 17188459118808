import styled from 'styled-components';

import { thingosColors } from '../../../shared/src/Theme/theme';

export const HeaderH1 = styled.h1({
	color: thingosColors.text.primary,
	fontWeight: 700,
	fontFamily:
		"Inter, Madefor, 'Helvetica Neue', Helvetica, Arial, 'メイリオ', 'meiryo', 'ヒラギノ角ゴ pro w3', 'hiragino kaku gothic pro', sans-serif",
	fontSize: '2.25rem',
	lineHeight: '3rem',
	marginTop: '2rem',
	marginBottom: '2rem',
});

export const HeaderH2 = styled.h2({
	color: thingosColors.text.primary,
	fontWeight: 700,
	fontFamily:
		"Inter, Madefor, 'Helvetica Neue', Helvetica, Arial, 'メイリオ', 'meiryo', 'ヒラギノ角ゴ pro w3', 'hiragino kaku gothic pro', sans-serif",
	fontSize: '1.5rem',
	lineHeight: '2.125rem',
	// marginTop: '2rem',
	marginBottom: '2rem',
});

export const HeaderH3 = styled.h3({
	color: thingosColors.text.primary,
	fontWeight: 700,
	fontFamily:
		"Inter, Madefor, 'Helvetica Neue', Helvetica, Arial, 'メイリオ', 'meiryo', 'ヒラギノ角ゴ pro w3', 'hiragino kaku gothic pro', sans-serif",
	fontSize: '1.125rem',
	lineHeight: '1.75rem',
	// marginTop: '2rem',
	marginBottom: '2rem',
});

export const Body = styled.p({
	maxWidth: 500,
	color: thingosColors.text.secondary,
	fontFamily:
		"Inter, Madefor, 'Helvetica Neue', Helvetica, Arial, 'メイリオ', 'meiryo', 'ヒラギノ角ゴ pro w3', 'hiragino kaku gothic pro', sans-serif",
	fontWeight: 500,
	fontSize: '1.125rem',
	lineHeight: '1.75rem',
	'& >strong': {
		fontWeight: 700,
		color: thingosColors.text.primary,
	},
});

export const Mark = styled.mark({
	color: thingosColors.text.secondary,
	backgroundColor: `${thingosColors.highlight.main}` + '15',
});

export const Footnote = styled.p({
	textAlign: 'center',
	color: thingosColors.text.hint,
	fontFamily:
		"Inter, Madefor, 'Helvetica Neue', Helvetica, Arial, 'メイリオ', 'meiryo', 'ヒラギノ角ゴ pro w3', 'hiragino kaku gothic pro', sans-serif",
	fontStyle: 'italic',
	fontWeight: 500,
	fontSize: '1.125rem',
	lineHeight: '1.75rem',
});

export const Quote = styled.blockquote({
	fontFamily:
		"Inter, Madefor, 'Helvetica Neue', Helvetica, Arial, 'メイリオ', 'meiryo', 'ヒラギノ角ゴ pro w3', 'hiragino kaku gothic pro', sans-serif",
	color: thingosColors.text.primary,
	fontWeight: 700,
	fontSize: '1.5rem',
	lineHeight: '2.125rem',
	fontStyle: 'italic',
	borderLeft: '3px solid',
	borderColor: thingosColors.highlight.secondary,
	padding: '3remm 10px',
	// margin: '3rem 10px',
	'&:before': {
		content: 'open-quote',
		color: thingosColors.highlight.secondary,
	},
	'&:after': {
		content: 'close-quote',
		color: thingosColors.highlight.secondary,
	},
});
