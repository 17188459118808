/**
 * Wave
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import * as React from 'react';

export type FlipDirection = 'none' | 'vertical' | 'horizontal' | 'both';

function scaleForFlipDirection(flipped?: FlipDirection) {
	switch (flipped) {
		case 'vertical':
			return 'scaleY(-1)';
		case 'horizontal':
			return 'scaleX(-1)';
		case 'both':
			return 'scaleX(-1) scaleY(-1)';
		default:
			return '';
	}
}
interface WaveProps {
	color?: string;
	flipped?: FlipDirection;
}
export const Wave: React.FC<WaveProps> = props => {
	const { color = '#ddd', flipped } = props;
	return (
		<div style={{ width: '100%', overflow: 'hidden' }}>
			<svg
				style={{ display: 'block', minWidth: '100rem', transform: scaleForFlipDirection(flipped) }}
				viewBox="0 0 1400 100"
				width="100%"
				height="100"
				xmlns="http://www.w3.org/2000/svg"
				preserveAspectRatio="none"
			>
				<path
					d="M0,100 C178.586606,70.2474594 312.307159,58.3711891 401.161657,58.3711891 C534.443405,58.3711891 594.238055,76.1855946 851,76.1855946 C1022.17463,76.1855946 1217.8413,50.7903964 1400,0 L1400,100 L0,100 Z"
					id="Path-2"
					fillRule="evenodd"
					fill={color}
				/>
			</svg>
		</div>
	);
};
