import * as React from 'react';

import { ImageContainer } from './Container';
import { Wave } from './Wave';

interface HeaderImageProps {
	image: string;
	waveColor?: string;
}

export const HeaderImage: React.FC<HeaderImageProps> = props => {
	const { image, waveColor } = props;
	return (
		<ImageContainer image={image} height="40rem">
			<div
				style={{
					height: '40rem',
					display: 'flex',
					alignItems: 'flex-end',
				}}
			>
				<Wave color={waveColor} />
			</div>
		</ImageContainer>
	);
};
