import styled from 'styled-components';

export const Tag = styled.p<{ color?: string; small?: boolean }>(({ color, small }) => ({
	margin: 0,
	backgroundColor: color + '33',
	color: color,
	fontSize: small ? '1.125rem' : '1.125rem',
	fontWeight: '700',
	lineHeight: '1.75rem',
	padding: '4px 8px 4px 8px',
	borderRadius: '4px',
}));
