import * as React from 'react';
import styled from 'styled-components';

import { thingosColors } from '../../../shared/src/Theme/theme';
import { Button } from './Button';
import { HeaderH1, Body } from './Typography';

const ContactLayout = styled.div<{ backgroundColor?: string }>(({ backgroundColor }) => ({
	alignItems: 'center',
	textAlign: 'center',
	display: 'flex',
	flexDirection: 'column',
	flex: '1',
	marginTop: '8rem',
	marginBottom: '8rem',
	paddingTop: '1rem',
	paddingBottom: '2rem',
	background: backgroundColor ? backgroundColor : thingosColors.background.light,
	border: '1px solid ' + thingosColors.text.hint,
	borderRadius: '8px',
}));

interface ContactProps {
	title: string;
	text: string;
	buttonText: string;
	url: string;
	backgroundColor?: string;
}

export const Contact: React.FC<ContactProps> = props => {
	const { title, text, buttonText, url, backgroundColor } = props;

	return (
		<ContactLayout backgroundColor={backgroundColor}>
			<HeaderH1 style={{ marginBottom: '0.5rem' }}>{title}</HeaderH1>
			<Body style={{ maxWidth: '100%', paddingLeft: '2rem', paddingRight: '2rem' }}>{text}</Body>
			<a href={url} style={{ textDecoration: 'none' }}>
				<Button color={thingosColors.highlight.secondary}>{buttonText}</Button>
			</a>
		</ContactLayout>
	);
};
